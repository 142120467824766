import { useCallback, useEffect, useState } from "react";
import { authentication, getContext } from "@microsoft/teams-js";

import { useTranslation } from "react-i18next";
import axios from "axios";
import { track } from "@amplitude/analytics-browser";

import {
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";
import { IChallenge, IUserChallenge } from "../types/challenge";

import ChallengeCard from "../components/ChallengeCard";
import _ from "lodash";

import { app } from "@microsoft/teams-js";

export default function Challenges() {
  const { t } = useTranslation();

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [isUserChallengesLoading, setIsUserChallengesLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [userChallenges, setUserChallenges] = useState<IUserChallenge[]>([]);
  const [selectedChallenge, setSelectedChallenge] = useState<string>();

  const [challengeData, setChallengeData] = useState<IChallenge>();

  const [isPrivate, setIsPrivate] = useState(true);

  useEffect(() => {
    app.getContext().then((context) => {
      track(
        context.channel
          ? `OpenChallengesTab-${context.channel.id}`
          : "OpenChallengesTab"
      );
      if (context.channel) {
        setIsPrivate(true);
      } else {
        setIsPrivate(false);
      }
    });
  }, []);

  useEffect(() => {
    setUserChallenges([]);
    setIsUserChallengesLoading(true);
    authentication
      .getAuthToken()
      .then((token) => {
        axios
          .get<IUserChallenge[]>(`/Challenges/GetUserChallenges`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data.length > 0) {
              setSelectedChallenge(res.data[0].challengeId.toString());
            }
            setUserChallenges(res.data);
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            setIsUserChallengesLoading(false);
          });
      })
      .catch((err) => {
        setIsUserChallengesLoading(false);
        console.log("err:", err);
      });
  }, []);

  const loadChallengeMetaData = useCallback(() => {
    if (!selectedChallenge) {
      return;
    }
    setIsLoading(true);
    authentication
      .getAuthToken()
      .then((token) => {
        axios
          .get<IChallenge>(`/Challenges/GetChallenge`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              challengeId: selectedChallenge,
            },
          })
          .then((res) => {
            setChallengeData((current) => ({ ...current, ...res.data }));
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err: ", err);
      });
  }, [selectedChallenge]);

  const loadChallengeData = useCallback(() => {
    if (!selectedChallenge) {
      return;
    }

    setChallengeData(undefined);
    setIsLoading(true);

    authentication
      .getAuthToken()
      .then((token) => {
        axios
          .get<IChallenge>(`/Challenges/GetChallengeQuizzes`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              challengeId: selectedChallenge,
            },
          })
          .then((res) => {
            setChallengeData(res.data);
          })
          .catch((err) => {
            console.log("err:", err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err: ", err);
      });
  }, [selectedChallenge]);

  useEffect(() => {
    loadChallengeData();
  }, [loadChallengeData]);

  return (
    <div>
      <Grid
        container
        alignItems="center"
        spacing={2}
        style={{ paddingBottom: 16 }}
      >
        {isPrivate ? (
          <Grid item>
            <Typography variant="h2" component="h1">
              {t("texts.myChallenges")}
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Typography variant="h2" component="h1">
                {t("texts.myChallenges")}
              </Typography>
            </Grid>
            <Grid item>
              {userChallenges.length > 0 && (
                <Select
                  value={selectedChallenge || ""}
                  onChange={(event) => {
                    setSelectedChallenge(event.target.value as string);
                  }}
                  variant="filled"
                  disableUnderline
                  renderValue={(value) => {
                    const challenge = userChallenges.find(
                      (challenge) => challenge.challengeId.toString() === value
                    );

                    if (!challenge) {
                      return "";
                    }

                    return `${challenge.contentName} in team ${challenge.teamName}`;
                  }}
                >
                  {userChallenges.map((challenge) => (
                    <MenuItem
                      key={challenge.challengeId.toString()}
                      value={challenge.challengeId.toString()}
                    >
                      <Grid item container direction="column">
                        <Grid item>
                          <Typography>
                            {t("challengeCard.challengeInTeam", {
                              contentName: challenge.contentName,
                              teamName: challenge.teamName,
                            })}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">
                            {moment.utc(challenge.startDate).format("L")} -{" "}
                            {moment.utc(challenge.endDate).format("L")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Grid>
          </>
        )}
      </Grid>

      {isLoading && (
        <Grid container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}

      {!isUserChallengesLoading && userChallenges.length === 0 && (
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="h5">
              {t("challengeCard.noChallenges")}
            </Typography>
          </Grid>
        </Grid>
      )}

      {challengeData ? (
        <Grid
          container
          direction="column"
          spacing={1}
          style={{
            paddingBottom: 16,
          }}
        >
          <Grid item>
            <Typography>
              <b>{t("challengeCard.challengeDuration")}</b>
              {`📅 ${moment.utc(challengeData.startDate).format("L")} - ${moment
                .utc(challengeData.endDate)
                .format("L")} (${
                moment.utc(challengeData.endDate).isAfter(moment())
                  ? t("challengeCard.active")
                  : t("challengeCard.inactive")
              })`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>{t("challengeCard.challengeGoal")}</b>{" "}
              {t("challengeCard.targetResults", {
                correctAnswersCriteria: challengeData.correctAnswersCriteria,
                answersGivenCriteria: challengeData.answersGivenCriteria,
              })}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>{t("challengeCard.answersGiven")}</b>{" "}
              {`${challengeData.quizzesAnsweredPercent}% (${challengeData.quizzesAnswered}/${challengeData.quizzesSent})`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>{t("challengeCard.correctAnswers")}</b>{" "}
              {`${challengeData.quizzesCorrectPercent}% (${challengeData.quizzesCorrect}/${challengeData.quizzesAnswered})`}
            </Typography>
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={4}>
        {_.zip(
          ..._.chunk(challengeData?.quizzes, smDown ? 1 : mdDown ? 2 : 3)
        ).map((column, index) => (
          <Grid
            key={index}
            container
            item
            xs
            spacing={4}
            direction="column"
            role="column"
          >
            {column.map((quiz) =>
              quiz ? (
                <Grid key={quiz.quizId} item>
                  <ChallengeCard
                    quiz={quiz}
                    callback={loadChallengeMetaData}
                    inactive={!challengeData}
                  />
                </Grid>
              ) : null
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
